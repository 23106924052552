import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function autoExecute(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'auto_id', label: 'auto_id' , sortable: true},
    { key: 'setting_id', label: '配置Id ' , sortable: true},
    { key: 'group_name', label: '分组名称' , sortable: true},
    { key: 'group_total', label: '分组条数' , sortable: true},
    { key: 'group_ord', label: '分组执行顺序' , sortable: true},
    { key: 'group_success', label: '分组是否已执行完成' , sortable: true},
    { key: 'para', label: '参数' , sortable: true},
    { key: 'run_times', label: '已执行次数' , sortable: true},
    { key: 'run_max_times', label: '最大执行次数' , sortable: true},
    { key: 'traceid', label: '跟踪ID' , sortable: true},
    { key: 'is_success', label: '是否已执行完成' , sortable: true},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref("auto_id")
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['autoExecuteList/getCondition']}
    store
        .dispatch('autoExecuteList/search', {
          auto_id: condition.auto_id,
          setting_id: condition.setting_id,
          group_name: condition.group_name==''?undefined:condition.group_name,
          group_success: condition.group_success,
          is_success: condition.is_success,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '自动执行程序列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
