<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal">
            <b-row>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="auto_id:"
                    label-for="auto_id"
                    label-size="sm"
                >
                  <b-form-input
                      id="auto_id"
                      v-model="condition.auto_id"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="配置Id:"
                    label-for="setting_id"
                    label-size="sm"
                >
                  <b-form-input
                      id="setting_id"
                      v-model="condition.setting_id"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="分组名称:"
                    label-for="group_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="group_name"
                      v-model="condition.group_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="当前组是否已执行完成:"
                    label-for="group_success"
                    label-size="sm"
                >
                  <v-select
                      id="group_success"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model = "condition.group_success"
                      class="select-size-sm"
                      placeholder="选择当前组是否已执行完成"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="是否已执行完成:"
                    label-for="is_success"
                    label-size="sm"
                >
                  <v-select
                      id="is_success"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model = "condition.is_success"
                      class="select-size-sm"
                      placeholder="选择是否已执行完成"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <!-- Columns -->
        <template #cell(auto_id)="data">
          {{data.item.auto_id}}
        </template>
        <template #cell(setting_id)="data">
          {{data.item.setting_id}}
        </template>
        <template #cell(group_name)="data">
          {{data.item.group_name}}
        </template>
        <template #cell(group_total)="data">
          {{data.item.group_total}}
        </template>
        <template #cell(group_ord)="data">
          {{data.item.group_ord}}
        </template>
        <template #cell(group_success)="data">
          {{getCodeLabel('yesno', data.item.group_success)}}
        </template>
        <template #cell(para)="data">
          <div :id="`para-${data.item.auto_id}`">
            <span v-if="data.item.para">
              {{ data.item.para.substring(0, 3) }}
              <span v-if="data.item.para.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`para-${data.item.auto_id}`" placement="top">
            {{ data.item.para }}
          </b-tooltip>
        </template>
        <template #cell(run_times)="data">
          {{data.item.run_times}}
        </template>
        <template #cell(run_max_times)="data">
          {{data.item.run_max_times}}
        </template>
        <template #cell(traceid)="data">
          <a class="link" :href="'http://jaeger.xhhuo.com/trace/'+data.item.traceid" target="_blank">{{data.item.traceid}}</a>
        </template>
        <template #cell(is_success)="data">
          {{getCodeLabel('yesno', data.item.is_success)}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty } from '@core/utils/filter'
import autoExecuteStore from "@/views/apps/autoexecute/autoExecuteStore";
import autoExecute from './autoExecute.js'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
  },
  data() {
    return {
      advanced_search_modal: false,
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('autoExecuteList/updateCondition', this.state.condition)
      this.refetchData()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('autoExecuteList')) store.registerModule('autoExecuteList', autoExecuteStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('autoExecuteList')) store.unregisterModule('autoExecuteList')
    })
    onMounted(() => {
      state.condition = store.getters['autoExecuteList/getCondition']
    })

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }


    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


    } = autoExecute(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      advanced_search,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
